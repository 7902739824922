import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxChatModule } from '@pazznetwork/ngx-chat';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPrintModule } from 'ngx-print';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from "ngx-ui-loader";
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DoctorLayoutComponent } from './doctor/doctor-layout/doctor-layout.component';
import { DoctorModule } from './doctor/doctor.module';
import { InterceptorService } from './shared/services/UserServices/interceptor.service';
import { LayoutModule } from './User/layout/layout.module';



const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#D1A272",
  bgsOpacity: 0.5,
  bgsPosition: "center-center",
  bgsSize: 60,
  bgsType: "ball-spin-clockwise",
  blur: 10,
  fgsType: "ball-spin-clockwise",
  fgsColor:"#D1A272",
  fgsSize: 20,
  gap: 10,
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(209,162,114,0.5)",
  pbThickness:0,
  logoPosition: "center-center",
  logoSize: 90,
  logoUrl: "./assets/User/img/dabur.png",
  text: "Loading...",
  textColor: "#D1A272",
  textPosition: "center-center",
};

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'askveda.com' // it is recommended to set your domain, for cookies to work properly
  },
  
  palette: {
    popup: {
      background: "#d1a272",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#616161",
      text: "#ffffff",
      border: "transparent"
    }
  },
  position: "bottom",
  theme: 'classic',
  type: 'info',
  content: {
    message: "We use cookies for analytical and functional purpose which assist us to serve you better.<br /> Kindly read privacy policy for more information and details.",
    dismiss: "Got it!",
    link: "Learn more",
    href: '/privacyPolicy',
    policy: "Cookie Policy"
  }
  
  
};

@NgModule({
  declarations: [
    AppComponent,
    DoctorLayoutComponent,
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    DoctorModule,
    NgxMaterialTimepickerModule,
    InfiniteScrollModule,
    PickerModule ,
    NgxChatModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    NgxPrintModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],

  providers: [{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },],
  bootstrap: [AppComponent]
})

export class AppModule { }
