import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DoctorRoutingModule } from './doctor-routing.module';
import { DoctorLayoutModule } from './doctor-layout/doctor-layout.module';
import { TopBarComponent } from './doctor-layout/shared/top-bar/top-bar.component';
import { LeftSidebarComponent } from './doctor-layout/shared/left-sidebar/left-sidebar.component';


@NgModule({
  declarations: [TopBarComponent, LeftSidebarComponent],
  imports: [
    CommonModule,
    DoctorRoutingModule,
    DoctorLayoutModule
  ],
  exports: [TopBarComponent, LeftSidebarComponent],
})
export class DoctorModule { }
