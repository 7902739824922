import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';

import { TopmenubarComponent } from './shared/topmenubar/topmenubar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { Router, RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';




@NgModule({
  declarations: [LayoutComponent,TopmenubarComponent,FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    InfiniteScrollModule,
  ]
})
export class LayoutModule { }
