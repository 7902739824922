import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }

  handleError(error: HttpErrorResponse) {

    console.error(error.error)
    switch (error.status) {
      case 401:      //login
        localStorage.removeItem('userData');
        this.router.navigateByUrl("/auth");
        break;
      case 403:     //forbidden
        localStorage.removeItem('userData');
        this.router.navigateByUrl("/auth");
        break;
    }
    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const userData = JSON.parse(localStorage.getItem('userData'));
    
    
    var httpOptions={}
  
    if (userData) {
      if(request.url.includes('httpfileupload')){
        request = request.clone();
      }else{
        httpOptions = {
          headers: new HttpHeaders({ 
            'Authorization': 'JWT ' + userData['token']
          })
        };
        request = request.clone(httpOptions);
      }
    }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    // }

    
    //request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    // return next.handle(request).pipe(
    //   map((event: HttpEvent<any>) => {
    //     if (event instanceof HttpResponse) {
    //     }
    //     return event;
    //   }));

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        }
        else {
          console.log('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        console.log(errorMsg);
        this.handleError(error)
        return throwError(error);
      }))
  };
}

