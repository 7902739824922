import { Component, OnInit } from '@angular/core';
import { UserservicesService } from 'src/app/shared/services/UserServices/userservices.service';
import { Router } from '@angular/router';
import {settingModel} from '../../../../Models/setting.model';
import { knowledgeModel } from 'src/app/Models/knowledgeCategories.model';
import { DoctorRoute,DoctorRoute1, HTTP_Type } from 'src/app/shared/constant';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  settingModelList:settingModel
  item;
  page = 1;
  pageSize =8;
  loading: boolean;
  knowledgeCategoriesModel:knowledgeModel;
  id
  name
  doctorDashboardURL = "";
  doctorDashboardURL1 = "";
  constructor(private router:Router, private userService:UserservicesService) { }

  ngOnInit() {
    this.doctorDashboardURL = HTTP_Type + document.location.host + DoctorRoute;
    this.doctorDashboardURL1 = HTTP_Type + document.location.host + DoctorRoute1;
    console.log(this.doctorDashboardURL);
    
    this.getSettings();
    this.knowledgeCategories()
  }
  getSettings() {
    const data={
      "search_string":"",
      "page":1
    }
    this.userService.getSettingDetails(data).subscribe((res)=>{
      this.settingModelList= res;
      if (this.settingModelList && this.settingModelList.data.length > 0){
        this.item=this.settingModelList.data[0];
      }
    //  console.log(this.settingModelList);
    })
  }

  knowledgeCategories(){
    var data = {
     "page":this.page,
     "record_per_page":this.pageSize
   }
 this.loading=true
   this.userService.getKnowledgeCategories(data).subscribe((res) => {
     //console.log(res);
     this.knowledgeCategoriesModel = res;
     console.log(this.knowledgeCategoriesModel)
 },
 (errro) => {
   this.loading = false;
 })

}
showKnowledgeDetail(name){
  // item.id = 64
  // item.name = "Kid's Health"
console.log(name)
  if(name == "Kid\'s Health"){
   
    window.location.href =('https://askveda.com/blog/?category=kid%27s-health') 
  }

  if(name == "Sexual Wellness"){
    
    window.location.href =('https://askveda.com/blog/?category=sexual-wellness') 
  }

  if(name == "Psychiatry Mental diseases"){
    
    window.location.href =('https://askveda.com/blog/?category=psychiatry-mental-diseases') 
  }

  if(name == "Weight Management"){
    
    window.location.href =('https://askveda.com/blog/?category=weight-management') 
  }

  if(name == "Stress/Anxiety Management"){
    
    window.location.href =('https://askveda.com/blog/?category=stress-anxiety-management') 
  }
  
 
  // let name2: String =name.split(' ').join('-');
  // let name3: String = name2.split(',').join('-');
  // let name4: String = name3.split('(').join('');
  // let name5: String = name4.split(')').join('');
  // let Name: String = name5.split('/').join('-');
  

  //  this.router.navigate(['/',Name.toLowerCase()]);
}

Cardiologist(item){
  if(item == "skin"){
    
    window.location.href =('https://askveda.com/ayurveda-doctors/?search=skin') 
  }
  
  // this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //   return false;
  // }
  // this.router.onSameUrlNavigation = 'reload';
  // localStorage.setItem('search_string', item);
  
  // this.router.navigate(['/ayurveda-doctors/',item]);
  

}

doctorRegister(){
  console.log("in regsis")
  window.location.href =('https://askveda.com/doctors-registration') 
}


}
