import { Component, OnInit } from '@angular/core';
import { Portal_Name } from 'src/app/shared/constant';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit {

  portal_name = Portal_Name;

  constructor() { }

  ngOnInit() {
  }

}
