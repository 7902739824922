import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './User/layout/layout.component';
import { DoctorLayoutComponent } from './doctor/doctor-layout/doctor-layout.component'
import { AuthGuard } from './core/auth.guard';



const routes: Routes = [

{ path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},


// doctor side paths start
{ path: 'doctor', component: DoctorLayoutComponent, loadChildren: () => import('./doctor/pages/pages.module').then(m => m.PagesModule)},
// doctor side paths end

{ path: '',  component: LayoutComponent, loadChildren: () => import('./User/pages/pages.module').then(m => m.PagesModule)},


];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
// RouterModule.forRoot(routes, { useHash: true })
  exports: [RouterModule]
})
export class AppRoutingModule { }
