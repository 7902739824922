import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Subscription } from 'rxjs';
import { NotificationListModel } from 'src/app/Models/NotificationListModel';
import { NotificationUnreadCountModel } from 'src/app/Models/NotificationUnreadCountModel';
import { settingModel } from 'src/app/Models/setting.model';
import { Data } from 'src/app/Models/usermodel';
import { Portal_Name } from 'src/app/shared/constant';
import { StateService } from 'src/app/shared/services/state.service';
import { UserservicesService } from 'src/app/shared/services/UserServices/userservices.service';

declare var $;

@Component({
  selector: 'app-topmenubar',
  templateUrl: './topmenubar.component.html',
  styleUrls: ['./topmenubar.component.css']
})
export class TopmenubarComponent implements OnInit {
  settingModelList:settingModel;
  user: any;
  userDetails:Data;
  fullName="";
  profile_image;
  phoneNumber;
  // MenuItem = [
  //   { label: 'Home', link: '' },
  //   { label: 'Discover Doctors', link: '/ayurveda-doctors/' },
  //   { label: 'Ask a Doctor', link: '/consult-a-doctor' },
  //   { 
  //     label: 'Blog', link:'/blog/?' 
  //   },
  //   { label: 'Support', link: '/get-support' }
  // ];

  subscription: Subscription;
  notificationListData:NotificationListModel;
  notificationUnreadCountModel: NotificationUnreadCountModel;
  currentPage = 1;
  isNotifData = false;

  portal_name = Portal_Name;

  constructor(private router:Router,private el: ElementRef, private stateService:StateService, private userService:UserservicesService, private ngxLoader:NgxUiLoaderService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getSettings()
    this.user = JSON.parse(localStorage.getItem('userData'));
    this.setUser()
    if(this.user){
      this.getUserProfileData();
      this.getNotificationUnreadCount();
      // Repeatative call for unread count
      const source = interval(60000);
      this.subscription = source.subscribe(val => this.getNotificationUnreadCount());
    }
    this.stateService.profileNameChange.subscribe((message) => {
      this.user = JSON.parse(localStorage.getItem('userData'));
      if (this.user){
        this.setUser()
        this.getUserProfileData();
      }
    });
  }

  getNotificationUnreadCount() {
    var params = {
      "users_id": this.user.id
    }
    this.userService.getUnreadCount(params).subscribe((res)=>{
      this.notificationUnreadCountModel = res;
      // console.log(this.notificationUnreadCountModel);
    }, (error)=> {
      // console.log(error.error);
    })
  }

  getNotifications() {
    var params = {
      "page":this.currentPage,
      "record_per_page":"5",
      "users_id":this.user.id
    }
    // this.ngxLoader.start();
    this.userService.getNotifications(params).subscribe((res)=>{
      if (this.currentPage === 1){
        this.notificationListData = res;
      } else {
        res.data.data.forEach(ele=>{
          this.notificationListData.data.data.push(ele);
        })
      }
      this.isNotifData = true;
      // this.ngxLoader.stop();
      this.readNotification(res.data.data);
    }, (error)=>{
      // console.log(error.error);
      if (this.currentPage === 1){
        if (this.notificationListData && this.notificationListData.data && this.notificationListData.data.data){
          this.notificationListData.data.data = [];
        }
      }
      this.isNotifData = true;
      // this.ngxLoader.stop();
    })
  }

  readNotification(fetchedNotifArray){
    var fetchedNotifications = [];
    if (fetchedNotifArray && fetchedNotifArray.length > 0){
      fetchedNotifArray.forEach(element=>{
        fetchedNotifications.push(element.id);
      })
      var params = {
        "users_id": this.user.id,
        "notifications_id": fetchedNotifications
      }
      this.userService.readNotifications(params).subscribe((res)=>{
        // console.log(res)
        this.getNotificationUnreadCount();
      }, (error)=>{
        console.log(error.error);
      });
    }
  }

  onScroll() {
    console.log('scrolled!!');
    this.currentPage++;
    this.getNotifications();
  }

  onNotificationClick(event){
    setTimeout(()=>{
      if (event.target.parentElement.getAttribute('aria-expanded')== "true"){
        console.log("open");
        this.currentPage = 1;
        this.getNotifications();
      } else {
        console.log("close");
        this.isNotifData = false;
        if (this.notificationListData && this.notificationListData.data && this.notificationListData.data.data){
          this.notificationListData.data.data = [];
        }
      }
    }, 1000);
  }

  filterNotificationMessage(inputMessage) {
    return this.sanitizer.bypassSecurityTrustHtml(inputMessage);
  }

  setUser(){
    if (this.user){
      if (this.user.avtar){
        this.profile_image = this.user.avtar+'?'+Math.floor(Math.random() * 100) + 1
      }else {
        this.profile_image = null;
      }
      this.fullName= this.user.first_name+" "+this.user.last_name;
      this.phoneNumber = this.user.phone;
    }
  }

  getProfile(){
    this.userDetails=JSON.parse(localStorage.getItem('userDetails'));
    this.fullName= this.userDetails.personal_details[0].first_name+" "+this.userDetails.personal_details[0].last_name;
    if (this.userDetails && this.userDetails.personal_details && this.userDetails.personal_details.length > 0 && this.userDetails.personal_details[0].avatar && this.userDetails.personal_details[0].avatar !== ""){
      this.profile_image = this.userDetails.personal_details[0].avatar+'?'+Math.floor(Math.random() * 100) + 1;
    } else {
      this.profile_image = null;
    }
    this.phoneNumber = this.userDetails.personal_details[0].phone;
      
  }

  getUserProfileData() {
    const param= {"users_id": this.user.id,"user_type": "patient"};
    this.userService.getUserProfile(param).subscribe((res)=>{
      if (res.response_code == 200){
        localStorage.setItem('userDetails', JSON.stringify(res.data))
        this.getProfile();
      }
    });
  }

  pageDetails(){
    $('.navbar-collapse').collapse('hide');
  }

  resister(){
    this.router.navigate(['/patient-login']);
    $('.navbar-collapse').collapse('hide');
  }

  viewUserAppoinments(){
     this.router.navigate(['/myappointments']);
     $('.navbar-collapse').collapse('hide');
  }

  viewUserPrescription(){
    this.router.navigate(['/userprescription']);
    $('.navbar-collapse').collapse('hide');
  }

  viewUserProfile(){
    this.router.navigate(['/userprofile']);
    $('.navbar-collapse').collapse('hide');
  }

  signOut(){
    localStorage.removeItem('token');
    localStorage.removeItem('appointmentDetails');
    localStorage.removeItem('doctorDetails');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('doctorData');
    localStorage.removeItem('userData');
    localStorage.removeItem('bookingFor');
    localStorage.removeItem('selectedDoctor');
    // localStorage.clear();
    this.router.navigate[''];
    window.location.reload();
  }

  getSettings() {
    const data={      
      "search_string":"",
      "page":1
    }
    this.userService.getSettingDetails(data).subscribe((res)=>{
      this.settingModelList= res;
    //  console.log(this.settingModelList);
    });
  }
}
