import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DoctorLayoutRoutingModule } from './doctor-layout-routing.module';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { LeftSidebarComponent } from './shared/left-sidebar/left-sidebar.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DoctorLayoutRoutingModule
  ],
  
})
export class DoctorLayoutModule { }
