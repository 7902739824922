// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // name: "Default",
  // api_endpoint: "https://laravel.techdemos.in/api/",
  // chat_service:{
  //   zoom_meeting_url:"https://laravel.techdemos.in/zoom_meeting",
  //   domain:"laravel.techdemos.in",
  //   uri:"wss://laravel.techdemos.in:7443/ws"
  // },
  // Other: {
  //   immunity_blog_id: "3",
  //   Privacy_policy_article_id: 384,
  //   T_and_C_article_id: 384,
  //   Disclaimer_id: 384,
  //   default_topic_id: 46,
  // },
  // cookieDomain: 'localhost'


  production: false,
  name: "Default",
  api_endpoint: "https://srvtst.dabur.com/api/",
  chat_service:{
    zoom_meeting_url:"https://askveda.com/zoom_meeting",
    domain:"srvtst.dabur.com",
    uri:"wss://srvtst.dabur.com:7443/ws"
  },
  Other: {
    immunity_blog_id: "3",
    Privacy_policy_article_id: 405,
    T_and_C_article_id: 384,
    Disclaimer_id: 384,
    default_topic_id: 46,
  },
  cookieDomain: 'localhost'
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
