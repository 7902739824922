import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class StateService {

  constructor() { }

  private currentProfileNameSource = new BehaviorSubject<string>('user');
  profileNameChange = this.currentProfileNameSource.asObservable();

  setCurrentProfileName(profile_name: string) {
    this.currentProfileNameSource.next(profile_name);
  }
}