import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators'
import { environment } from './../../../../environments/environment';


//const endpoint = "http://35.154.37.239/api/"
//const endpoint = "http://demo.techdemos.in/api/" //new server for client
//const endpoint = "https://openfire.techdemos.in/api/" //iAT XMPP Server


const endpoint = environment.api_endpoint

const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class UserservicesService {

  constructor(private http: HttpClient) { }

  loginUser(data: any): Observable<any> {
    return this.http.post<any>(endpoint + 'login', data, { headers: headers });
  }

  resisterUser(data: any) {
    return this.http.post<any>(endpoint + 'patient_registration', data, { headers: headers });
  }

  getDoctors(data: any) {
    return this.http.post<any>(endpoint + 'get_users', data, { headers: headers });
  }

  getTopics(data): Observable<any> {
    return this.http.get<any>(endpoint + "get_topics", data);
  }

  //User Profile Data
  getUserProfile(data) {
    return this.http.post<any>(endpoint + 'get_user_detail', data, { headers: headers });
  }

  //Master Data
  getUserMasterData() {
    return this.http.get<any>(endpoint + 'master_data', { headers: headers });
  }

  updatePassword(data) {
    return this.http.post<any>(endpoint + 'change_password', data, { headers: headers });
  }

  getKnowledgeCategories(data: any) {
    return this.http.post<any>(endpoint + 'get_knowledge_categories', data, { headers: headers });
  }

  get_Faqs(data: any) {
    return this.http.post<any>(endpoint + 'get_faqs', data, { headers: headers });
  }

  getProductCategories(data: any) {
    return this.http.post<any>(endpoint + 'get_knowledge_categories', data, { headers: headers });
  }

  getknowledgedetail(data) {
    //get_articles
    return this.http.post<any>(endpoint + 'get_articles', data, { headers: headers });
  }

  get_Articles(data) {
    return this.http.post<any>(endpoint + 'get_articles', data, { headers: headers });
  }

  get_Doctorscomments(data) {
    return this.http.post<any>(endpoint + 'get_comments', data, { headers: headers });
  }

  viewDoctorDetails(data) {
    return this.http.post<any>(endpoint + 'get_user_detail', data, { headers: headers });
  }

  //update_patient_profile
  updatePatientProfile(data) {
    return this.http.post<any>(endpoint + 'update_patient_profile', data);
  }

  //bookAppoinment
  bookAppoinment(data) {
    return this.http.post<any>(endpoint + 'book_appointment', data);
  }

  // get Prescription
  getPrescriptions(data) {
    return this.http.post<any>(endpoint + 'get_prescriptions', data);
  }

  // get Prescription Detail
  getPrescriptionsDetail(id) {
    console.log("iddddd" + id)
    return this.http.get<any>(endpoint + 'get_prescription_detail/' + id, { headers: headers })
  }

  getWebinars(data) {
    return this.http.post<any>(endpoint + 'get_webinars', data, { headers: headers });
  }

  getAppointment(data) {
    return this.http.post<any>(endpoint + 'get_appointment', data, { headers: headers });
  }

  get_PatientsQuestion(data) {
    return this.http.post<any>(endpoint + 'get_articles', data, { headers: headers });
  }

  get_RelatedQuestion(data) {
    return this.http.post<any>(endpoint + 'get_articles', data, { headers: headers });
  }

  submitSupport(data) {
    return this.http.post<any>(endpoint + 'submit_support', data, { headers: headers });
  }

  getDoctorAvailability(data): Observable<any> {
    return this.http.post<any>(endpoint + 'check_availability', data, { headers: headers })
  }

  patientQuestionDetail(data): Observable<any> {
    return this.http.post<any>(endpoint + 'add_update_article', data, { headers: headers })
  }

  //get_store
  getStoreLocationList(data): Observable<any> {
    return this.http.post<any>(endpoint + "get_store", data, { headers: headers });
  }

  getStoreLocationByCity(data): Observable<any> {
    return this.http.post<any>(endpoint + "get_store", data, { headers: headers });
  }

  //get_testimonials
  getTestimonialsDetail(): Observable<any> {
    var data = {}
    return this.http.post<any>(endpoint + "get_testimonials", data, { headers: headers });
  }

  getSettingDetails(data): Observable<any> {
    return this.http.post<any>(endpoint + "get_settings", data);
  }

  getSlider(): Observable<any> {
    let data = {}
    return this.http.post<any>(endpoint + "get_slider", data,{ headers: headers });
  }

  getTestData(param: any): Observable<any> {
    return this.http.post<any>(endpoint + "get_test", param, { headers: headers });
  }

  updateViewCount(data) {
    return this.http.post<any>(endpoint + 'view_count', data, { headers: headers });
  }

  updateShareCount(data) {
    return this.http.post<any>(endpoint + 'share_count', data, { headers: headers });
  }

  getAppointments(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'get_appointment', param, { headers: headers })
  }

  subscribeNewsletter(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'subscribe_newsletter', param, { headers: headers })
  }

  createZoomMeeting(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'create_zoom_meeting', param, { headers: headers })
  }

  changeConsultationDate(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'change_consultation_date', param, { headers: headers })
  }

  submitTest(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'result_user', param, { headers: headers })
  }

  assignTestResult(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'assign_test_result', param, { headers: headers })
  }

  forgotPassword(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'forgot_password', param, { headers: headers })
  }

  setPassword(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'set_password', param, { headers: headers })
  }

  verifyLink(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'verify_link', param, { headers: headers })
  }

  consultationUpdate(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'consultation_update', param, { headers: headers })
  }

  rateDoctor(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'rate_appointment', param, { headers: headers })
  }

  getNotifications(params: any):Observable<any> {
    return this.http.post<any>(endpoint + 'get_notification', params, { headers:headers});
  }
  registerWebinar(data) {
    return this.http.post<any>(endpoint + 'register_webinar', data, { headers: headers });
  }

  getUnreadCount(params: any):Observable<any> {
    return this.http.post<any>(endpoint + 'unread_notification_count', params, { headers:headers});
  }

  readNotifications(params: any):Observable<any> {
    return this.http.post<any>(endpoint + 'read_notifications', params, { headers:headers});
  }

  unsubscribeNewsletter(param: any): Observable<any> {
    return this.http.post<any>(endpoint + 'unsubscribe_newsletter', param, { headers: headers })
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error('Error' + error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
